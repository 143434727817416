// DO NOT MODIFY THIS FILE DIRECTLY!
// This file is automatically generated by scripts/generate-code/generate-code.ts
/**
 * ISO 3166-1 country code records
 */
export const records = [
  {
    name: 'Afghanistan',
    name_fr: "Afghanistan (l')",
    alpha2: 'AF',
    alpha3: 'AFG',
    numeric: '004',
  },
  {
    name: 'Albania',
    name_fr: "Albanie (l')",
    alpha2: 'AL',
    alpha3: 'ALB',
    numeric: '008',
  },
  {
    name: 'Algeria',
    name_fr: "Algérie (l')",
    alpha2: 'DZ',
    alpha3: 'DZA',
    numeric: '012',
  },
  {
    name: 'American Samoa',
    name_fr: 'Samoa américaines (les)',
    alpha2: 'AS',
    alpha3: 'ASM',
    numeric: '016',
  },
  {
    name: 'Andorra',
    name_fr: "Andorre (l')",
    alpha2: 'AD',
    alpha3: 'AND',
    numeric: '020',
  },
  {
    name: 'Angola',
    name_fr: "Angola (l')",
    alpha2: 'AO',
    alpha3: 'AGO',
    numeric: '024',
  },
  {
    name: 'Anguilla',
    name_fr: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    numeric: '660',
  },
  {
    name: 'Antarctica',
    name_fr: "Antarctique (l')",
    alpha2: 'AQ',
    alpha3: 'ATA',
    numeric: '010',
  },
  {
    name: 'Antigua and Barbuda',
    name_fr: 'Antigua-et-Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    numeric: '028',
  },
  {
    name: 'Argentina',
    name_fr: "Argentine (l')",
    alpha2: 'AR',
    alpha3: 'ARG',
    numeric: '032',
  },
  {
    name: 'Armenia',
    name_fr: "Arménie (l')",
    alpha2: 'AM',
    alpha3: 'ARM',
    numeric: '051',
  },
  {
    name: 'Aruba',
    name_fr: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    numeric: '533',
  },
  {
    name: 'Australia',
    name_fr: "Australie (l')",
    alpha2: 'AU',
    alpha3: 'AUS',
    numeric: '036',
  },
  {
    name: 'Austria',
    name_fr: "Autriche (l')",
    alpha2: 'AT',
    alpha3: 'AUT',
    numeric: '040',
  },
  {
    name: 'Azerbaijan',
    name_fr: "Azerbaïdjan (l')",
    alpha2: 'AZ',
    alpha3: 'AZE',
    numeric: '031',
  },
  {
    name: 'Bahamas (the)',
    name_fr: 'Bahamas (les)',
    alpha2: 'BS',
    alpha3: 'BHS',
    numeric: '044',
  },
  {
    name: 'Bahrain',
    name_fr: 'Bahreïn',
    alpha2: 'BH',
    alpha3: 'BHR',
    numeric: '048',
  },
  {
    name: 'Bangladesh',
    name_fr: 'Bangladesh (le)',
    alpha2: 'BD',
    alpha3: 'BGD',
    numeric: '050',
  },
  {
    name: 'Barbados',
    name_fr: 'Barbade (la)',
    alpha2: 'BB',
    alpha3: 'BRB',
    numeric: '052',
  },
  {
    name: 'Belarus',
    name_fr: 'Bélarus (le)',
    alpha2: 'BY',
    alpha3: 'BLR',
    numeric: '112',
  },
  {
    name: 'Belgium',
    name_fr: 'Belgique (la)',
    alpha2: 'BE',
    alpha3: 'BEL',
    numeric: '056',
  },
  {
    name: 'Belize',
    name_fr: 'Belize (le)',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    numeric: '084',
  },
  {
    name: 'Benin',
    name_fr: 'Bénin (le)',
    alpha2: 'BJ',
    alpha3: 'BEN',
    numeric: '204',
  },
  {
    name: 'Bermuda',
    name_fr: 'Bermudes (les)',
    alpha2: 'BM',
    alpha3: 'BMU',
    numeric: '060',
  },
  {
    name: 'Bhutan',
    name_fr: 'Bhoutan (le)',
    alpha2: 'BT',
    alpha3: 'BTN',
    numeric: '064',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    name_fr: 'Bolivie (État plurinational de)',
    alpha2: 'BO',
    alpha3: 'BOL',
    numeric: '068',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    name_fr: 'Bonaire, Saint-Eustache et Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    numeric: '535',
  },
  {
    name: 'Bosnia and Herzegovina',
    name_fr: 'Bosnie-Herzégovine (la)',
    alpha2: 'BA',
    alpha3: 'BIH',
    numeric: '070',
  },
  {
    name: 'Botswana',
    name_fr: 'Botswana (le)',
    alpha2: 'BW',
    alpha3: 'BWA',
    numeric: '072',
  },
  {
    name: 'Bouvet Island',
    name_fr: "Bouvet (l'Île)",
    alpha2: 'BV',
    alpha3: 'BVT',
    numeric: '074',
  },
  {
    name: 'Brazil',
    name_fr: 'Brésil (le)',
    alpha2: 'BR',
    alpha3: 'BRA',
    numeric: '076',
  },
  {
    name: 'British Indian Ocean Territory (the)',
    name_fr: "Indien (le Territoire britannique de l'océan)",
    alpha2: 'IO',
    alpha3: 'IOT',
    numeric: '086',
  },
  {
    name: 'Brunei Darussalam',
    name_fr: 'Brunéi Darussalam (le)',
    alpha2: 'BN',
    alpha3: 'BRN',
    numeric: '096',
  },
  {
    name: 'Bulgaria',
    name_fr: 'Bulgarie (la)',
    alpha2: 'BG',
    alpha3: 'BGR',
    numeric: '100',
  },
  {
    name: 'Burkina Faso',
    name_fr: 'Burkina Faso (le)',
    alpha2: 'BF',
    alpha3: 'BFA',
    numeric: '854',
  },
  {
    name: 'Burundi',
    name_fr: 'Burundi (le)',
    alpha2: 'BI',
    alpha3: 'BDI',
    numeric: '108',
  },
  {
    name: 'Cabo Verde',
    name_fr: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    numeric: '132',
  },
  {
    name: 'Cambodia',
    name_fr: 'Cambodge (le)',
    alpha2: 'KH',
    alpha3: 'KHM',
    numeric: '116',
  },
  {
    name: 'Cameroon',
    name_fr: 'Cameroun (le)',
    alpha2: 'CM',
    alpha3: 'CMR',
    numeric: '120',
  },
  {
    name: 'Canada',
    name_fr: 'Canada (le)',
    alpha2: 'CA',
    alpha3: 'CAN',
    numeric: '124',
  },
  {
    name: 'Cayman Islands (the)',
    name_fr: 'Caïmans (les Îles)',
    alpha2: 'KY',
    alpha3: 'CYM',
    numeric: '136',
  },
  {
    name: 'Central African Republic (the)',
    name_fr: 'République centrafricaine (la)',
    alpha2: 'CF',
    alpha3: 'CAF',
    numeric: '140',
  },
  {
    name: 'Chad',
    name_fr: 'Tchad (le)',
    alpha2: 'TD',
    alpha3: 'TCD',
    numeric: '148',
  },
  {
    name: 'Chile',
    name_fr: 'Chili (le)',
    alpha2: 'CL',
    alpha3: 'CHL',
    numeric: '152',
  },
  {
    name: 'China',
    name_fr: 'Chine (la)',
    alpha2: 'CN',
    alpha3: 'CHN',
    numeric: '156',
  },
  {
    name: 'Christmas Island',
    name_fr: "Christmas (l'Île)",
    alpha2: 'CX',
    alpha3: 'CXR',
    numeric: '162',
  },
  {
    name: 'Cocos (Keeling) Islands (the)',
    name_fr: 'Cocos (les Îles)/ Keeling (les Îles)',
    alpha2: 'CC',
    alpha3: 'CCK',
    numeric: '166',
  },
  {
    name: 'Colombia',
    name_fr: 'Colombie (la)',
    alpha2: 'CO',
    alpha3: 'COL',
    numeric: '170',
  },
  {
    name: 'Comoros (the)',
    name_fr: 'Comores (les)',
    alpha2: 'KM',
    alpha3: 'COM',
    numeric: '174',
  },
  {
    name: 'Congo (the Democratic Republic of the)',
    name_fr: 'Congo (la République démocratique du)',
    alpha2: 'CD',
    alpha3: 'COD',
    numeric: '180',
  },
  {
    name: 'Congo (the)',
    name_fr: 'Congo (le)',
    alpha2: 'CG',
    alpha3: 'COG',
    numeric: '178',
  },
  {
    name: 'Cook Islands (the)',
    name_fr: 'Cook (les Îles)',
    alpha2: 'CK',
    alpha3: 'COK',
    numeric: '184',
  },
  {
    name: 'Costa Rica',
    name_fr: 'Costa Rica (le)',
    alpha2: 'CR',
    alpha3: 'CRI',
    numeric: '188',
  },
  {
    name: 'Croatia',
    name_fr: 'Croatie (la)',
    alpha2: 'HR',
    alpha3: 'HRV',
    numeric: '191',
  },
  {
    name: 'Cuba',
    name_fr: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    numeric: '192',
  },
  {
    name: 'Curaçao',
    name_fr: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    numeric: '531',
  },
  {
    name: 'Cyprus',
    name_fr: 'Chypre',
    alpha2: 'CY',
    alpha3: 'CYP',
    numeric: '196',
  },
  {
    name: 'Czechia',
    name_fr: 'Tchéquie (la)',
    alpha2: 'CZ',
    alpha3: 'CZE',
    numeric: '203',
  },
  {
    name: "Côte d'Ivoire",
    name_fr: "Côte d'Ivoire (la)",
    alpha2: 'CI',
    alpha3: 'CIV',
    numeric: '384',
  },
  {
    name: 'Denmark',
    name_fr: 'Danemark (le)',
    alpha2: 'DK',
    alpha3: 'DNK',
    numeric: '208',
  },
  {
    name: 'Djibouti',
    name_fr: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    numeric: '262',
  },
  {
    name: 'Dominica',
    name_fr: 'Dominique (la)',
    alpha2: 'DM',
    alpha3: 'DMA',
    numeric: '212',
  },
  {
    name: 'Dominican Republic (the)',
    name_fr: 'dominicaine (la République)',
    alpha2: 'DO',
    alpha3: 'DOM',
    numeric: '214',
  },
  {
    name: 'Ecuador',
    name_fr: "Équateur (l')",
    alpha2: 'EC',
    alpha3: 'ECU',
    numeric: '218',
  },
  {
    name: 'Egypt',
    name_fr: "Égypte (l')",
    alpha2: 'EG',
    alpha3: 'EGY',
    numeric: '818',
  },
  {
    name: 'El Salvador',
    name_fr: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    numeric: '222',
  },
  {
    name: 'Equatorial Guinea',
    name_fr: 'Guinée équatoriale (la)',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    numeric: '226',
  },
  {
    name: 'Eritrea',
    name_fr: "Érythrée (l')",
    alpha2: 'ER',
    alpha3: 'ERI',
    numeric: '232',
  },
  {
    name: 'Estonia',
    name_fr: "Estonie (l')",
    alpha2: 'EE',
    alpha3: 'EST',
    numeric: '233',
  },
  {
    name: 'Eswatini',
    name_fr: "Eswatini (l')",
    alpha2: 'SZ',
    alpha3: 'SWZ',
    numeric: '748',
  },
  {
    name: 'Ethiopia',
    name_fr: "Éthiopie (l')",
    alpha2: 'ET',
    alpha3: 'ETH',
    numeric: '231',
  },
  {
    name: 'Falkland Islands (the) [Malvinas]',
    name_fr: 'Falkland (les Îles)/Malouines (les Îles)',
    alpha2: 'FK',
    alpha3: 'FLK',
    numeric: '238',
  },
  {
    name: 'Faroe Islands (the)',
    name_fr: 'Féroé (les Îles)',
    alpha2: 'FO',
    alpha3: 'FRO',
    numeric: '234',
  },
  {
    name: 'Fiji',
    name_fr: 'Fidji (les)',
    alpha2: 'FJ',
    alpha3: 'FJI',
    numeric: '242',
  },
  {
    name: 'Finland',
    name_fr: 'Finlande (la)',
    alpha2: 'FI',
    alpha3: 'FIN',
    numeric: '246',
  },
  {
    name: 'France',
    name_fr: 'France (la)',
    alpha2: 'FR',
    alpha3: 'FRA',
    numeric: '250',
  },
  {
    name: 'French Guiana',
    name_fr: 'Guyane française (la )',
    alpha2: 'GF',
    alpha3: 'GUF',
    numeric: '254',
  },
  {
    name: 'French Polynesia',
    name_fr: 'Polynésie française (la)',
    alpha2: 'PF',
    alpha3: 'PYF',
    numeric: '258',
  },
  {
    name: 'French Southern Territories (the)',
    name_fr: 'Terres australes françaises (les)',
    alpha2: 'TF',
    alpha3: 'ATF',
    numeric: '260',
  },
  {
    name: 'Gabon',
    name_fr: 'Gabon (le)',
    alpha2: 'GA',
    alpha3: 'GAB',
    numeric: '266',
  },
  {
    name: 'Gambia (the)',
    name_fr: 'Gambie (la)',
    alpha2: 'GM',
    alpha3: 'GMB',
    numeric: '270',
  },
  {
    name: 'Georgia',
    name_fr: 'Géorgie (la)',
    alpha2: 'GE',
    alpha3: 'GEO',
    numeric: '268',
  },
  {
    name: 'Germany',
    name_fr: "Allemagne (l')",
    alpha2: 'DE',
    alpha3: 'DEU',
    numeric: '276',
  },
  {
    name: 'Ghana',
    name_fr: 'Ghana (le)',
    alpha2: 'GH',
    alpha3: 'GHA',
    numeric: '288',
  },
  {
    name: 'Gibraltar',
    name_fr: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    numeric: '292',
  },
  {
    name: 'Greece',
    name_fr: 'Grèce (la)',
    alpha2: 'GR',
    alpha3: 'GRC',
    numeric: '300',
  },
  {
    name: 'Greenland',
    name_fr: 'Groenland (le)',
    alpha2: 'GL',
    alpha3: 'GRL',
    numeric: '304',
  },
  {
    name: 'Grenada',
    name_fr: 'Grenade (la)',
    alpha2: 'GD',
    alpha3: 'GRD',
    numeric: '308',
  },
  {
    name: 'Guadeloupe',
    name_fr: 'Guadeloupe (la)',
    alpha2: 'GP',
    alpha3: 'GLP',
    numeric: '312',
  },
  {
    name: 'Guam',
    name_fr: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    numeric: '316',
  },
  {
    name: 'Guatemala',
    name_fr: 'Guatemala (le)',
    alpha2: 'GT',
    alpha3: 'GTM',
    numeric: '320',
  },
  {
    name: 'Guernsey',
    name_fr: 'Guernesey',
    alpha2: 'GG',
    alpha3: 'GGY',
    numeric: '831',
  },
  {
    name: 'Guinea',
    name_fr: 'Guinée (la)',
    alpha2: 'GN',
    alpha3: 'GIN',
    numeric: '324',
  },
  {
    name: 'Guinea-Bissau',
    name_fr: 'Guinée-Bissau (la)',
    alpha2: 'GW',
    alpha3: 'GNB',
    numeric: '624',
  },
  {
    name: 'Guyana',
    name_fr: 'Guyana (le)',
    alpha2: 'GY',
    alpha3: 'GUY',
    numeric: '328',
  },
  {
    name: 'Haiti',
    name_fr: 'Haïti',
    alpha2: 'HT',
    alpha3: 'HTI',
    numeric: '332',
  },
  {
    name: 'Heard Island and McDonald Islands',
    name_fr: "Heard-et-Îles MacDonald (l'Île)",
    alpha2: 'HM',
    alpha3: 'HMD',
    numeric: '334',
  },
  {
    name: 'Holy See (the)',
    name_fr: 'Saint-Siège (le)',
    alpha2: 'VA',
    alpha3: 'VAT',
    numeric: '336',
  },
  {
    name: 'Honduras',
    name_fr: 'Honduras (le)',
    alpha2: 'HN',
    alpha3: 'HND',
    numeric: '340',
  },
  {
    name: 'Hong Kong',
    name_fr: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    numeric: '344',
  },
  {
    name: 'Hungary',
    name_fr: 'Hongrie (la)',
    alpha2: 'HU',
    alpha3: 'HUN',
    numeric: '348',
  },
  {
    name: 'Iceland',
    name_fr: "Islande (l')",
    alpha2: 'IS',
    alpha3: 'ISL',
    numeric: '352',
  },
  {
    name: 'India',
    name_fr: "Inde (l')",
    alpha2: 'IN',
    alpha3: 'IND',
    numeric: '356',
  },
  {
    name: 'Indonesia',
    name_fr: "Indonésie (l')",
    alpha2: 'ID',
    alpha3: 'IDN',
    numeric: '360',
  },
  {
    name: 'Iran (Islamic Republic of)',
    name_fr: "Iran (République Islamique d')",
    alpha2: 'IR',
    alpha3: 'IRN',
    numeric: '364',
  },
  {
    name: 'Iraq',
    name_fr: "Iraq (l')",
    alpha2: 'IQ',
    alpha3: 'IRQ',
    numeric: '368',
  },
  {
    name: 'Ireland',
    name_fr: "Irlande (l')",
    alpha2: 'IE',
    alpha3: 'IRL',
    numeric: '372',
  },
  {
    name: 'Isle of Man',
    name_fr: 'Île de Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    numeric: '833',
  },
  {
    name: 'Israel',
    name_fr: 'Israël',
    alpha2: 'IL',
    alpha3: 'ISR',
    numeric: '376',
  },
  {
    name: 'Italy',
    name_fr: "Italie (l')",
    alpha2: 'IT',
    alpha3: 'ITA',
    numeric: '380',
  },
  {
    name: 'Jamaica',
    name_fr: 'Jamaïque (la)',
    alpha2: 'JM',
    alpha3: 'JAM',
    numeric: '388',
  },
  {
    name: 'Japan',
    name_fr: 'Japon (le)',
    alpha2: 'JP',
    alpha3: 'JPN',
    numeric: '392',
  },
  {
    name: 'Jersey',
    name_fr: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    numeric: '832',
  },
  {
    name: 'Jordan',
    name_fr: 'Jordanie (la)',
    alpha2: 'JO',
    alpha3: 'JOR',
    numeric: '400',
  },
  {
    name: 'Kazakhstan',
    name_fr: 'Kazakhstan (le)',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    numeric: '398',
  },
  {
    name: 'Kenya',
    name_fr: 'Kenya (le)',
    alpha2: 'KE',
    alpha3: 'KEN',
    numeric: '404',
  },
  {
    name: 'Kiribati',
    name_fr: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    numeric: '296',
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    name_fr: 'Corée (la République populaire démocratique de)',
    alpha2: 'KP',
    alpha3: 'PRK',
    numeric: '408',
  },
  {
    name: 'Korea (the Republic of)',
    name_fr: 'Corée (la République de)',
    alpha2: 'KR',
    alpha3: 'KOR',
    numeric: '410',
  },
  {
    name: 'Kuwait',
    name_fr: 'Koweït (le)',
    alpha2: 'KW',
    alpha3: 'KWT',
    numeric: '414',
  },
  {
    name: 'Kyrgyzstan',
    name_fr: 'Kirghizistan (le)',
    alpha2: 'KG',
    alpha3: 'KGZ',
    numeric: '417',
  },
  {
    name: "Lao People's Democratic Republic (the)",
    name_fr: 'Lao (la République démocratique populaire)',
    alpha2: 'LA',
    alpha3: 'LAO',
    numeric: '418',
  },
  {
    name: 'Latvia',
    name_fr: 'Lettonie (la)',
    alpha2: 'LV',
    alpha3: 'LVA',
    numeric: '428',
  },
  {
    name: 'Lebanon',
    name_fr: 'Liban (le)',
    alpha2: 'LB',
    alpha3: 'LBN',
    numeric: '422',
  },
  {
    name: 'Lesotho',
    name_fr: 'Lesotho (le)',
    alpha2: 'LS',
    alpha3: 'LSO',
    numeric: '426',
  },
  {
    name: 'Liberia',
    name_fr: 'Libéria (le)',
    alpha2: 'LR',
    alpha3: 'LBR',
    numeric: '430',
  },
  {
    name: 'Libya',
    name_fr: 'Libye (la)',
    alpha2: 'LY',
    alpha3: 'LBY',
    numeric: '434',
  },
  {
    name: 'Liechtenstein',
    name_fr: 'Liechtenstein (le)',
    alpha2: 'LI',
    alpha3: 'LIE',
    numeric: '438',
  },
  {
    name: 'Lithuania',
    name_fr: 'Lituanie (la)',
    alpha2: 'LT',
    alpha3: 'LTU',
    numeric: '440',
  },
  {
    name: 'Luxembourg',
    name_fr: 'Luxembourg (le)',
    alpha2: 'LU',
    alpha3: 'LUX',
    numeric: '442',
  },
  {
    name: 'Macao',
    name_fr: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    numeric: '446',
  },
  {
    name: 'Madagascar',
    name_fr: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    numeric: '450',
  },
  {
    name: 'Malawi',
    name_fr: 'Malawi (le)',
    alpha2: 'MW',
    alpha3: 'MWI',
    numeric: '454',
  },
  {
    name: 'Malaysia',
    name_fr: 'Malaisie (la)',
    alpha2: 'MY',
    alpha3: 'MYS',
    numeric: '458',
  },
  {
    name: 'Maldives',
    name_fr: 'Maldives (les)',
    alpha2: 'MV',
    alpha3: 'MDV',
    numeric: '462',
  },
  {
    name: 'Mali',
    name_fr: 'Mali (le)',
    alpha2: 'ML',
    alpha3: 'MLI',
    numeric: '466',
  },
  {
    name: 'Malta',
    name_fr: 'Malte',
    alpha2: 'MT',
    alpha3: 'MLT',
    numeric: '470',
  },
  {
    name: 'Marshall Islands (the)',
    name_fr: 'Marshall (les Îles)',
    alpha2: 'MH',
    alpha3: 'MHL',
    numeric: '584',
  },
  {
    name: 'Martinique',
    name_fr: 'Martinique (la)',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    numeric: '474',
  },
  {
    name: 'Mauritania',
    name_fr: 'Mauritanie (la)',
    alpha2: 'MR',
    alpha3: 'MRT',
    numeric: '478',
  },
  {
    name: 'Mauritius',
    name_fr: 'Maurice',
    alpha2: 'MU',
    alpha3: 'MUS',
    numeric: '480',
  },
  {
    name: 'Mayotte',
    name_fr: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    numeric: '175',
  },
  {
    name: 'Mexico',
    name_fr: 'Mexique (le)',
    alpha2: 'MX',
    alpha3: 'MEX',
    numeric: '484',
  },
  {
    name: 'Micronesia (Federated States of)',
    name_fr: 'Micronésie (États fédérés de)',
    alpha2: 'FM',
    alpha3: 'FSM',
    numeric: '583',
  },
  {
    name: 'Moldova (the Republic of)',
    name_fr: 'Moldova (la République de)',
    alpha2: 'MD',
    alpha3: 'MDA',
    numeric: '498',
  },
  {
    name: 'Monaco',
    name_fr: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    numeric: '492',
  },
  {
    name: 'Mongolia',
    name_fr: 'Mongolie (la)',
    alpha2: 'MN',
    alpha3: 'MNG',
    numeric: '496',
  },
  {
    name: 'Montenegro',
    name_fr: 'Monténégro (le)',
    alpha2: 'ME',
    alpha3: 'MNE',
    numeric: '499',
  },
  {
    name: 'Montserrat',
    name_fr: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    numeric: '500',
  },
  {
    name: 'Morocco',
    name_fr: 'Maroc (le)',
    alpha2: 'MA',
    alpha3: 'MAR',
    numeric: '504',
  },
  {
    name: 'Mozambique',
    name_fr: 'Mozambique (le)',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    numeric: '508',
  },
  {
    name: 'Myanmar',
    name_fr: 'Myanmar (le)',
    alpha2: 'MM',
    alpha3: 'MMR',
    numeric: '104',
  },
  {
    name: 'Namibia',
    name_fr: 'Namibie (la)',
    alpha2: 'NA',
    alpha3: 'NAM',
    numeric: '516',
  },
  {
    name: 'Nauru',
    name_fr: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    numeric: '520',
  },
  {
    name: 'Nepal',
    name_fr: 'Népal (le)',
    alpha2: 'NP',
    alpha3: 'NPL',
    numeric: '524',
  },
  {
    name: 'Netherlands (the)',
    name_fr: 'Pays-Bas (les)',
    alpha2: 'NL',
    alpha3: 'NLD',
    numeric: '528',
  },
  {
    name: 'New Caledonia',
    name_fr: 'Nouvelle-Calédonie (la)',
    alpha2: 'NC',
    alpha3: 'NCL',
    numeric: '540',
  },
  {
    name: 'New Zealand',
    name_fr: 'Nouvelle-Zélande (la)',
    alpha2: 'NZ',
    alpha3: 'NZL',
    numeric: '554',
  },
  {
    name: 'Nicaragua',
    name_fr: 'Nicaragua (le)',
    alpha2: 'NI',
    alpha3: 'NIC',
    numeric: '558',
  },
  {
    name: 'Niger (the)',
    name_fr: 'Niger (le)',
    alpha2: 'NE',
    alpha3: 'NER',
    numeric: '562',
  },
  {
    name: 'Nigeria',
    name_fr: 'Nigéria (le)',
    alpha2: 'NG',
    alpha3: 'NGA',
    numeric: '566',
  },
  {
    name: 'Niue',
    name_fr: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    numeric: '570',
  },
  {
    name: 'Norfolk Island',
    name_fr: "Norfolk (l'Île)",
    alpha2: 'NF',
    alpha3: 'NFK',
    numeric: '574',
  },
  {
    name: 'North Macedonia',
    name_fr: 'Macédoine du Nord (la)',
    alpha2: 'MK',
    alpha3: 'MKD',
    numeric: '807',
  },
  {
    name: 'Northern Mariana Islands (the)',
    name_fr: 'Mariannes du Nord (les Îles)',
    alpha2: 'MP',
    alpha3: 'MNP',
    numeric: '580',
  },
  {
    name: 'Norway',
    name_fr: 'Norvège (la)',
    alpha2: 'NO',
    alpha3: 'NOR',
    numeric: '578',
  },
  {
    name: 'Oman',
    name_fr: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    numeric: '512',
  },
  {
    name: 'Pakistan',
    name_fr: 'Pakistan (le)',
    alpha2: 'PK',
    alpha3: 'PAK',
    numeric: '586',
  },
  {
    name: 'Palau',
    name_fr: 'Palaos (les)',
    alpha2: 'PW',
    alpha3: 'PLW',
    numeric: '585',
  },
  {
    name: 'Palestine, State of',
    name_fr: 'Palestine, État de',
    alpha2: 'PS',
    alpha3: 'PSE',
    numeric: '275',
  },
  {
    name: 'Panama',
    name_fr: 'Panama (le)',
    alpha2: 'PA',
    alpha3: 'PAN',
    numeric: '591',
  },
  {
    name: 'Papua New Guinea',
    name_fr: 'Papouasie-Nouvelle-Guinée (la)',
    alpha2: 'PG',
    alpha3: 'PNG',
    numeric: '598',
  },
  {
    name: 'Paraguay',
    name_fr: 'Paraguay (le)',
    alpha2: 'PY',
    alpha3: 'PRY',
    numeric: '600',
  },
  {
    name: 'Peru',
    name_fr: 'Pérou (le)',
    alpha2: 'PE',
    alpha3: 'PER',
    numeric: '604',
  },
  {
    name: 'Philippines (the)',
    name_fr: 'Philippines (les)',
    alpha2: 'PH',
    alpha3: 'PHL',
    numeric: '608',
  },
  {
    name: 'Pitcairn',
    name_fr: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    numeric: '612',
  },
  {
    name: 'Poland',
    name_fr: 'Pologne (la)',
    alpha2: 'PL',
    alpha3: 'POL',
    numeric: '616',
  },
  {
    name: 'Portugal',
    name_fr: 'Portugal (le)',
    alpha2: 'PT',
    alpha3: 'PRT',
    numeric: '620',
  },
  {
    name: 'Puerto Rico',
    name_fr: 'Porto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    numeric: '630',
  },
  {
    name: 'Qatar',
    name_fr: 'Qatar (le)',
    alpha2: 'QA',
    alpha3: 'QAT',
    numeric: '634',
  },
  {
    name: 'Romania',
    name_fr: 'Roumanie (la)',
    alpha2: 'RO',
    alpha3: 'ROU',
    numeric: '642',
  },
  {
    name: 'Russian Federation (the)',
    name_fr: 'Russie (la Fédération de)',
    alpha2: 'RU',
    alpha3: 'RUS',
    numeric: '643',
  },
  {
    name: 'Rwanda',
    name_fr: 'Rwanda (le)',
    alpha2: 'RW',
    alpha3: 'RWA',
    numeric: '646',
  },
  {
    name: 'Réunion',
    name_fr: 'Réunion (La)',
    alpha2: 'RE',
    alpha3: 'REU',
    numeric: '638',
  },
  {
    name: 'Saint Barthélemy',
    name_fr: 'Saint-Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    numeric: '652',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    name_fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    numeric: '654',
  },
  {
    name: 'Saint Kitts and Nevis',
    name_fr: 'Saint-Kitts-et-Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    numeric: '659',
  },
  {
    name: 'Saint Lucia',
    name_fr: 'Sainte-Lucie',
    alpha2: 'LC',
    alpha3: 'LCA',
    numeric: '662',
  },
  {
    name: 'Saint Martin (French part)',
    name_fr: 'Saint-Martin (partie française)',
    alpha2: 'MF',
    alpha3: 'MAF',
    numeric: '663',
  },
  {
    name: 'Saint Pierre and Miquelon',
    name_fr: 'Saint-Pierre-et-Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    numeric: '666',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    name_fr: 'Saint-Vincent-et-les Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    numeric: '670',
  },
  {
    name: 'Samoa',
    name_fr: 'Samoa (le)',
    alpha2: 'WS',
    alpha3: 'WSM',
    numeric: '882',
  },
  {
    name: 'San Marino',
    name_fr: 'Saint-Marin',
    alpha2: 'SM',
    alpha3: 'SMR',
    numeric: '674',
  },
  {
    name: 'Sao Tome and Principe',
    name_fr: 'Sao Tomé-et-Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    numeric: '678',
  },
  {
    name: 'Saudi Arabia',
    name_fr: "Arabie saoudite (l')",
    alpha2: 'SA',
    alpha3: 'SAU',
    numeric: '682',
  },
  {
    name: 'Senegal',
    name_fr: 'Sénégal (le)',
    alpha2: 'SN',
    alpha3: 'SEN',
    numeric: '686',
  },
  {
    name: 'Serbia',
    name_fr: 'Serbie (la)',
    alpha2: 'RS',
    alpha3: 'SRB',
    numeric: '688',
  },
  {
    name: 'Seychelles',
    name_fr: 'Seychelles (les)',
    alpha2: 'SC',
    alpha3: 'SYC',
    numeric: '690',
  },
  {
    name: 'Sierra Leone',
    name_fr: 'Sierra Leone (la)',
    alpha2: 'SL',
    alpha3: 'SLE',
    numeric: '694',
  },
  {
    name: 'Singapore',
    name_fr: 'Singapour',
    alpha2: 'SG',
    alpha3: 'SGP',
    numeric: '702',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    name_fr: 'Saint-Martin (partie néerlandaise)',
    alpha2: 'SX',
    alpha3: 'SXM',
    numeric: '534',
  },
  {
    name: 'Slovakia',
    name_fr: 'Slovaquie (la)',
    alpha2: 'SK',
    alpha3: 'SVK',
    numeric: '703',
  },
  {
    name: 'Slovenia',
    name_fr: 'Slovénie (la)',
    alpha2: 'SI',
    alpha3: 'SVN',
    numeric: '705',
  },
  {
    name: 'Solomon Islands',
    name_fr: 'Salomon (les Îles)',
    alpha2: 'SB',
    alpha3: 'SLB',
    numeric: '090',
  },
  {
    name: 'Somalia',
    name_fr: 'Somalie (la)',
    alpha2: 'SO',
    alpha3: 'SOM',
    numeric: '706',
  },
  {
    name: 'South Africa',
    name_fr: "Afrique du Sud (l')",
    alpha2: 'ZA',
    alpha3: 'ZAF',
    numeric: '710',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    name_fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud (la)',
    alpha2: 'GS',
    alpha3: 'SGS',
    numeric: '239',
  },
  {
    name: 'South Sudan',
    name_fr: 'Soudan du Sud (le)',
    alpha2: 'SS',
    alpha3: 'SSD',
    numeric: '728',
  },
  {
    name: 'Spain',
    name_fr: "Espagne (l')",
    alpha2: 'ES',
    alpha3: 'ESP',
    numeric: '724',
  },
  {
    name: 'Sri Lanka',
    name_fr: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    numeric: '144',
  },
  {
    name: 'Sudan (the)',
    name_fr: 'Soudan (le)',
    alpha2: 'SD',
    alpha3: 'SDN',
    numeric: '729',
  },
  {
    name: 'Suriname',
    name_fr: 'Suriname (le)',
    alpha2: 'SR',
    alpha3: 'SUR',
    numeric: '740',
  },
  {
    name: 'Svalbard and Jan Mayen',
    name_fr: "Svalbard et l'Île Jan Mayen (le)",
    alpha2: 'SJ',
    alpha3: 'SJM',
    numeric: '744',
  },
  {
    name: 'Sweden',
    name_fr: 'Suède (la)',
    alpha2: 'SE',
    alpha3: 'SWE',
    numeric: '752',
  },
  {
    name: 'Switzerland',
    name_fr: 'Suisse (la)',
    alpha2: 'CH',
    alpha3: 'CHE',
    numeric: '756',
  },
  {
    name: 'Syrian Arab Republic (the)',
    name_fr: 'République arabe syrienne (la)',
    alpha2: 'SY',
    alpha3: 'SYR',
    numeric: '760',
  },
  {
    name: 'Taiwan (Province of China)',
    name_fr: 'Taïwan (Province de Chine)',
    alpha2: 'TW',
    alpha3: 'TWN',
    numeric: '158',
  },
  {
    name: 'Tajikistan',
    name_fr: 'Tadjikistan (le)',
    alpha2: 'TJ',
    alpha3: 'TJK',
    numeric: '762',
  },
  {
    name: 'Tanzania, the United Republic of',
    name_fr: 'Tanzanie (la République-Unie de)',
    alpha2: 'TZ',
    alpha3: 'TZA',
    numeric: '834',
  },
  {
    name: 'Thailand',
    name_fr: 'Thaïlande (la)',
    alpha2: 'TH',
    alpha3: 'THA',
    numeric: '764',
  },
  {
    name: 'Timor-Leste',
    name_fr: 'Timor-Leste (le)',
    alpha2: 'TL',
    alpha3: 'TLS',
    numeric: '626',
  },
  {
    name: 'Togo',
    name_fr: 'Togo (le)',
    alpha2: 'TG',
    alpha3: 'TGO',
    numeric: '768',
  },
  {
    name: 'Tokelau',
    name_fr: 'Tokelau (les)',
    alpha2: 'TK',
    alpha3: 'TKL',
    numeric: '772',
  },
  {
    name: 'Tonga',
    name_fr: 'Tonga (les)',
    alpha2: 'TO',
    alpha3: 'TON',
    numeric: '776',
  },
  {
    name: 'Trinidad and Tobago',
    name_fr: 'Trinité-et-Tobago (la)',
    alpha2: 'TT',
    alpha3: 'TTO',
    numeric: '780',
  },
  {
    name: 'Tunisia',
    name_fr: 'Tunisie (la)',
    alpha2: 'TN',
    alpha3: 'TUN',
    numeric: '788',
  },
  {
    name: 'Turkey',
    name_fr: 'Turquie (la)',
    alpha2: 'TR',
    alpha3: 'TUR',
    numeric: '792',
  },
  {
    name: 'Turkmenistan',
    name_fr: 'Turkménistan (le)',
    alpha2: 'TM',
    alpha3: 'TKM',
    numeric: '795',
  },
  {
    name: 'Turks and Caicos Islands (the)',
    name_fr: 'Turks-et-Caïcos (les Îles)',
    alpha2: 'TC',
    alpha3: 'TCA',
    numeric: '796',
  },
  {
    name: 'Tuvalu',
    name_fr: 'Tuvalu (les)',
    alpha2: 'TV',
    alpha3: 'TUV',
    numeric: '798',
  },
  {
    name: 'Uganda',
    name_fr: "Ouganda (l')",
    alpha2: 'UG',
    alpha3: 'UGA',
    numeric: '800',
  },
  {
    name: 'Ukraine',
    name_fr: "Ukraine (l')",
    alpha2: 'UA',
    alpha3: 'UKR',
    numeric: '804',
  },
  {
    name: 'United Arab Emirates (the)',
    name_fr: 'Émirats arabes unis (les)',
    alpha2: 'AE',
    alpha3: 'ARE',
    numeric: '784',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    name_fr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    alpha2: 'GB',
    alpha3: 'GBR',
    numeric: '826',
  },
  {
    name: 'United States Minor Outlying Islands (the)',
    name_fr: 'Îles mineures éloignées des États-Unis (les)',
    alpha2: 'UM',
    alpha3: 'UMI',
    numeric: '581',
  },
  {
    name: 'United States of America (the)',
    name_fr: "États-Unis d'Amérique (les)",
    alpha2: 'US',
    alpha3: 'USA',
    numeric: '840',
  },
  {
    name: 'Uruguay',
    name_fr: "Uruguay (l')",
    alpha2: 'UY',
    alpha3: 'URY',
    numeric: '858',
  },
  {
    name: 'Uzbekistan',
    name_fr: "Ouzbékistan (l')",
    alpha2: 'UZ',
    alpha3: 'UZB',
    numeric: '860',
  },
  {
    name: 'Vanuatu',
    name_fr: 'Vanuatu (le)',
    alpha2: 'VU',
    alpha3: 'VUT',
    numeric: '548',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    name_fr: 'Venezuela (République bolivarienne du)',
    alpha2: 'VE',
    alpha3: 'VEN',
    numeric: '862',
  },
  {
    name: 'Viet Nam',
    name_fr: 'Viet Nam (le)',
    alpha2: 'VN',
    alpha3: 'VNM',
    numeric: '704',
  },
  {
    name: 'Virgin Islands (British)',
    name_fr: 'Vierges britanniques (les Îles)',
    alpha2: 'VG',
    alpha3: 'VGB',
    numeric: '092',
  },
  {
    name: 'Virgin Islands (U.S.)',
    name_fr: 'Vierges des États-Unis (les Îles)',
    alpha2: 'VI',
    alpha3: 'VIR',
    numeric: '850',
  },
  {
    name: 'Wallis and Futuna',
    name_fr: 'Wallis-et-Futuna ',
    alpha2: 'WF',
    alpha3: 'WLF',
    numeric: '876',
  },
  {
    name: 'Western Sahara*',
    name_fr: 'Sahara occidental (le)*',
    alpha2: 'EH',
    alpha3: 'ESH',
    numeric: '732',
  },
  {
    name: 'Yemen',
    name_fr: 'Yémen (le)',
    alpha2: 'YE',
    alpha3: 'YEM',
    numeric: '887',
  },
  {
    name: 'Zambia',
    name_fr: 'Zambie (la)',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    numeric: '894',
  },
  {
    name: 'Zimbabwe',
    name_fr: 'Zimbabwe (le)',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    numeric: '716',
  },
  {
    name: 'Åland Islands',
    name_fr: 'Åland(les Îles)',
    alpha2: 'AX',
    alpha3: 'ALA',
    numeric: '248',
  },
] as const;

/**
 * ISO 3166-1 country code English short names
 */
export const names = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Macedonia',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic (the)',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, the United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara*',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
] as const;
/**
 * ISO 3166-1 country code English short names
 */
export type EnglishShortName = typeof names[number];

/**
 * ISO 3166-1 country code French short names
 */
export const namesFr = [
  "Afghanistan (l')",
  "Albanie (l')",
  "Algérie (l')",
  'Samoa américaines (les)',
  "Andorre (l')",
  "Angola (l')",
  'Anguilla',
  "Antarctique (l')",
  'Antigua-et-Barbuda',
  "Argentine (l')",
  "Arménie (l')",
  'Aruba',
  "Australie (l')",
  "Autriche (l')",
  "Azerbaïdjan (l')",
  'Bahamas (les)',
  'Bahreïn',
  'Bangladesh (le)',
  'Barbade (la)',
  'Bélarus (le)',
  'Belgique (la)',
  'Belize (le)',
  'Bénin (le)',
  'Bermudes (les)',
  'Bhoutan (le)',
  'Bolivie (État plurinational de)',
  'Bonaire, Saint-Eustache et Saba',
  'Bosnie-Herzégovine (la)',
  'Botswana (le)',
  "Bouvet (l'Île)",
  'Brésil (le)',
  "Indien (le Territoire britannique de l'océan)",
  'Brunéi Darussalam (le)',
  'Bulgarie (la)',
  'Burkina Faso (le)',
  'Burundi (le)',
  'Cabo Verde',
  'Cambodge (le)',
  'Cameroun (le)',
  'Canada (le)',
  'Caïmans (les Îles)',
  'République centrafricaine (la)',
  'Tchad (le)',
  'Chili (le)',
  'Chine (la)',
  "Christmas (l'Île)",
  'Cocos (les Îles)/ Keeling (les Îles)',
  'Colombie (la)',
  'Comores (les)',
  'Congo (la République démocratique du)',
  'Congo (le)',
  'Cook (les Îles)',
  'Costa Rica (le)',
  'Croatie (la)',
  'Cuba',
  'Curaçao',
  'Chypre',
  'Tchéquie (la)',
  "Côte d'Ivoire (la)",
  'Danemark (le)',
  'Djibouti',
  'Dominique (la)',
  'dominicaine (la République)',
  "Équateur (l')",
  "Égypte (l')",
  'El Salvador',
  'Guinée équatoriale (la)',
  "Érythrée (l')",
  "Estonie (l')",
  "Eswatini (l')",
  "Éthiopie (l')",
  'Falkland (les Îles)/Malouines (les Îles)',
  'Féroé (les Îles)',
  'Fidji (les)',
  'Finlande (la)',
  'France (la)',
  'Guyane française (la )',
  'Polynésie française (la)',
  'Terres australes françaises (les)',
  'Gabon (le)',
  'Gambie (la)',
  'Géorgie (la)',
  "Allemagne (l')",
  'Ghana (le)',
  'Gibraltar',
  'Grèce (la)',
  'Groenland (le)',
  'Grenade (la)',
  'Guadeloupe (la)',
  'Guam',
  'Guatemala (le)',
  'Guernesey',
  'Guinée (la)',
  'Guinée-Bissau (la)',
  'Guyana (le)',
  'Haïti',
  "Heard-et-Îles MacDonald (l'Île)",
  'Saint-Siège (le)',
  'Honduras (le)',
  'Hong Kong',
  'Hongrie (la)',
  "Islande (l')",
  "Inde (l')",
  "Indonésie (l')",
  "Iran (République Islamique d')",
  "Iraq (l')",
  "Irlande (l')",
  'Île de Man',
  'Israël',
  "Italie (l')",
  'Jamaïque (la)',
  'Japon (le)',
  'Jersey',
  'Jordanie (la)',
  'Kazakhstan (le)',
  'Kenya (le)',
  'Kiribati',
  'Corée (la République populaire démocratique de)',
  'Corée (la République de)',
  'Koweït (le)',
  'Kirghizistan (le)',
  'Lao (la République démocratique populaire)',
  'Lettonie (la)',
  'Liban (le)',
  'Lesotho (le)',
  'Libéria (le)',
  'Libye (la)',
  'Liechtenstein (le)',
  'Lituanie (la)',
  'Luxembourg (le)',
  'Macao',
  'Madagascar',
  'Malawi (le)',
  'Malaisie (la)',
  'Maldives (les)',
  'Mali (le)',
  'Malte',
  'Marshall (les Îles)',
  'Martinique (la)',
  'Mauritanie (la)',
  'Maurice',
  'Mayotte',
  'Mexique (le)',
  'Micronésie (États fédérés de)',
  'Moldova (la République de)',
  'Monaco',
  'Mongolie (la)',
  'Monténégro (le)',
  'Montserrat',
  'Maroc (le)',
  'Mozambique (le)',
  'Myanmar (le)',
  'Namibie (la)',
  'Nauru',
  'Népal (le)',
  'Pays-Bas (les)',
  'Nouvelle-Calédonie (la)',
  'Nouvelle-Zélande (la)',
  'Nicaragua (le)',
  'Niger (le)',
  'Nigéria (le)',
  'Niue',
  "Norfolk (l'Île)",
  'Macédoine du Nord (la)',
  'Mariannes du Nord (les Îles)',
  'Norvège (la)',
  'Oman',
  'Pakistan (le)',
  'Palaos (les)',
  'Palestine, État de',
  'Panama (le)',
  'Papouasie-Nouvelle-Guinée (la)',
  'Paraguay (le)',
  'Pérou (le)',
  'Philippines (les)',
  'Pitcairn',
  'Pologne (la)',
  'Portugal (le)',
  'Porto Rico',
  'Qatar (le)',
  'Roumanie (la)',
  'Russie (la Fédération de)',
  'Rwanda (le)',
  'Réunion (La)',
  'Saint-Barthélemy',
  'Sainte-Hélène, Ascension et Tristan da Cunha',
  'Saint-Kitts-et-Nevis',
  'Sainte-Lucie',
  'Saint-Martin (partie française)',
  'Saint-Pierre-et-Miquelon',
  'Saint-Vincent-et-les Grenadines',
  'Samoa (le)',
  'Saint-Marin',
  'Sao Tomé-et-Principe',
  "Arabie saoudite (l')",
  'Sénégal (le)',
  'Serbie (la)',
  'Seychelles (les)',
  'Sierra Leone (la)',
  'Singapour',
  'Saint-Martin (partie néerlandaise)',
  'Slovaquie (la)',
  'Slovénie (la)',
  'Salomon (les Îles)',
  'Somalie (la)',
  "Afrique du Sud (l')",
  'Géorgie du Sud-et-les Îles Sandwich du Sud (la)',
  'Soudan du Sud (le)',
  "Espagne (l')",
  'Sri Lanka',
  'Soudan (le)',
  'Suriname (le)',
  "Svalbard et l'Île Jan Mayen (le)",
  'Suède (la)',
  'Suisse (la)',
  'République arabe syrienne (la)',
  'Taïwan (Province de Chine)',
  'Tadjikistan (le)',
  'Tanzanie (la République-Unie de)',
  'Thaïlande (la)',
  'Timor-Leste (le)',
  'Togo (le)',
  'Tokelau (les)',
  'Tonga (les)',
  'Trinité-et-Tobago (la)',
  'Tunisie (la)',
  'Turquie (la)',
  'Turkménistan (le)',
  'Turks-et-Caïcos (les Îles)',
  'Tuvalu (les)',
  "Ouganda (l')",
  "Ukraine (l')",
  'Émirats arabes unis (les)',
  "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
  'Îles mineures éloignées des États-Unis (les)',
  "États-Unis d'Amérique (les)",
  "Uruguay (l')",
  "Ouzbékistan (l')",
  'Vanuatu (le)',
  'Venezuela (République bolivarienne du)',
  'Viet Nam (le)',
  'Vierges britanniques (les Îles)',
  'Vierges des États-Unis (les Îles)',
  'Wallis-et-Futuna ',
  'Sahara occidental (le)*',
  'Yémen (le)',
  'Zambie (la)',
  'Zimbabwe (le)',
  'Åland(les Îles)',
] as const;
/**
 * ISO 3166-1 country code French short names
 */
export type FrenchShortName = typeof namesFr[number];

/**
 * ISO 3166-1 Alpha-2 codes
 */
export const alpha2Codes = [
  'AF',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'CA',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CD',
  'CG',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'CI',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'SZ',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GH',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NG',
  'NU',
  'NF',
  'MK',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'PR',
  'QA',
  'RO',
  'RU',
  'RW',
  'RE',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TG',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'UM',
  'US',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
  'AX',
] as const;
/**
 * ISO 3166-1 Alpha-2 codes
 */
export type Alpha2Code = typeof alpha2Codes[number];

/**
 * ISO 3166-1 Alpha-3 codes
 */
export const alpha3Codes = [
  'AFG',
  'ALB',
  'DZA',
  'ASM',
  'AND',
  'AGO',
  'AIA',
  'ATA',
  'ATG',
  'ARG',
  'ARM',
  'ABW',
  'AUS',
  'AUT',
  'AZE',
  'BHS',
  'BHR',
  'BGD',
  'BRB',
  'BLR',
  'BEL',
  'BLZ',
  'BEN',
  'BMU',
  'BTN',
  'BOL',
  'BES',
  'BIH',
  'BWA',
  'BVT',
  'BRA',
  'IOT',
  'BRN',
  'BGR',
  'BFA',
  'BDI',
  'CPV',
  'KHM',
  'CMR',
  'CAN',
  'CYM',
  'CAF',
  'TCD',
  'CHL',
  'CHN',
  'CXR',
  'CCK',
  'COL',
  'COM',
  'COD',
  'COG',
  'COK',
  'CRI',
  'HRV',
  'CUB',
  'CUW',
  'CYP',
  'CZE',
  'CIV',
  'DNK',
  'DJI',
  'DMA',
  'DOM',
  'ECU',
  'EGY',
  'SLV',
  'GNQ',
  'ERI',
  'EST',
  'SWZ',
  'ETH',
  'FLK',
  'FRO',
  'FJI',
  'FIN',
  'FRA',
  'GUF',
  'PYF',
  'ATF',
  'GAB',
  'GMB',
  'GEO',
  'DEU',
  'GHA',
  'GIB',
  'GRC',
  'GRL',
  'GRD',
  'GLP',
  'GUM',
  'GTM',
  'GGY',
  'GIN',
  'GNB',
  'GUY',
  'HTI',
  'HMD',
  'VAT',
  'HND',
  'HKG',
  'HUN',
  'ISL',
  'IND',
  'IDN',
  'IRN',
  'IRQ',
  'IRL',
  'IMN',
  'ISR',
  'ITA',
  'JAM',
  'JPN',
  'JEY',
  'JOR',
  'KAZ',
  'KEN',
  'KIR',
  'PRK',
  'KOR',
  'KWT',
  'KGZ',
  'LAO',
  'LVA',
  'LBN',
  'LSO',
  'LBR',
  'LBY',
  'LIE',
  'LTU',
  'LUX',
  'MAC',
  'MDG',
  'MWI',
  'MYS',
  'MDV',
  'MLI',
  'MLT',
  'MHL',
  'MTQ',
  'MRT',
  'MUS',
  'MYT',
  'MEX',
  'FSM',
  'MDA',
  'MCO',
  'MNG',
  'MNE',
  'MSR',
  'MAR',
  'MOZ',
  'MMR',
  'NAM',
  'NRU',
  'NPL',
  'NLD',
  'NCL',
  'NZL',
  'NIC',
  'NER',
  'NGA',
  'NIU',
  'NFK',
  'MKD',
  'MNP',
  'NOR',
  'OMN',
  'PAK',
  'PLW',
  'PSE',
  'PAN',
  'PNG',
  'PRY',
  'PER',
  'PHL',
  'PCN',
  'POL',
  'PRT',
  'PRI',
  'QAT',
  'ROU',
  'RUS',
  'RWA',
  'REU',
  'BLM',
  'SHN',
  'KNA',
  'LCA',
  'MAF',
  'SPM',
  'VCT',
  'WSM',
  'SMR',
  'STP',
  'SAU',
  'SEN',
  'SRB',
  'SYC',
  'SLE',
  'SGP',
  'SXM',
  'SVK',
  'SVN',
  'SLB',
  'SOM',
  'ZAF',
  'SGS',
  'SSD',
  'ESP',
  'LKA',
  'SDN',
  'SUR',
  'SJM',
  'SWE',
  'CHE',
  'SYR',
  'TWN',
  'TJK',
  'TZA',
  'THA',
  'TLS',
  'TGO',
  'TKL',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TKM',
  'TCA',
  'TUV',
  'UGA',
  'UKR',
  'ARE',
  'GBR',
  'UMI',
  'USA',
  'URY',
  'UZB',
  'VUT',
  'VEN',
  'VNM',
  'VGB',
  'VIR',
  'WLF',
  'ESH',
  'YEM',
  'ZMB',
  'ZWE',
  'ALA',
] as const;
/**
 * ISO 3166-1 Alpha-3 codes
 */
export type Alpha3Code = typeof alpha3Codes[number];

/**
 * ISO 3166-1 Numeric codes
 */
export const numericCodes = [
  '004',
  '008',
  '012',
  '016',
  '020',
  '024',
  '660',
  '010',
  '028',
  '032',
  '051',
  '533',
  '036',
  '040',
  '031',
  '044',
  '048',
  '050',
  '052',
  '112',
  '056',
  '084',
  '204',
  '060',
  '064',
  '068',
  '535',
  '070',
  '072',
  '074',
  '076',
  '086',
  '096',
  '100',
  '854',
  '108',
  '132',
  '116',
  '120',
  '124',
  '136',
  '140',
  '148',
  '152',
  '156',
  '162',
  '166',
  '170',
  '174',
  '180',
  '178',
  '184',
  '188',
  '191',
  '192',
  '531',
  '196',
  '203',
  '384',
  '208',
  '262',
  '212',
  '214',
  '218',
  '818',
  '222',
  '226',
  '232',
  '233',
  '748',
  '231',
  '238',
  '234',
  '242',
  '246',
  '250',
  '254',
  '258',
  '260',
  '266',
  '270',
  '268',
  '276',
  '288',
  '292',
  '300',
  '304',
  '308',
  '312',
  '316',
  '320',
  '831',
  '324',
  '624',
  '328',
  '332',
  '334',
  '336',
  '340',
  '344',
  '348',
  '352',
  '356',
  '360',
  '364',
  '368',
  '372',
  '833',
  '376',
  '380',
  '388',
  '392',
  '832',
  '400',
  '398',
  '404',
  '296',
  '408',
  '410',
  '414',
  '417',
  '418',
  '428',
  '422',
  '426',
  '430',
  '434',
  '438',
  '440',
  '442',
  '446',
  '450',
  '454',
  '458',
  '462',
  '466',
  '470',
  '584',
  '474',
  '478',
  '480',
  '175',
  '484',
  '583',
  '498',
  '492',
  '496',
  '499',
  '500',
  '504',
  '508',
  '104',
  '516',
  '520',
  '524',
  '528',
  '540',
  '554',
  '558',
  '562',
  '566',
  '570',
  '574',
  '807',
  '580',
  '578',
  '512',
  '586',
  '585',
  '275',
  '591',
  '598',
  '600',
  '604',
  '608',
  '612',
  '616',
  '620',
  '630',
  '634',
  '642',
  '643',
  '646',
  '638',
  '652',
  '654',
  '659',
  '662',
  '663',
  '666',
  '670',
  '882',
  '674',
  '678',
  '682',
  '686',
  '688',
  '690',
  '694',
  '702',
  '534',
  '703',
  '705',
  '090',
  '706',
  '710',
  '239',
  '728',
  '724',
  '144',
  '729',
  '740',
  '744',
  '752',
  '756',
  '760',
  '158',
  '762',
  '834',
  '764',
  '626',
  '768',
  '772',
  '776',
  '780',
  '788',
  '792',
  '795',
  '796',
  '798',
  '800',
  '804',
  '784',
  '826',
  '581',
  '840',
  '858',
  '860',
  '548',
  '862',
  '704',
  '092',
  '850',
  '876',
  '732',
  '887',
  '894',
  '716',
  '248',
] as const;
/**
 * ISO 3166-1 Numeric codes
 */
export type NumericCode = typeof numericCodes[number];
